import * as React from "react"
import { useCallback } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import FileUpload from "../components/form/FileUpload"
import Map from "../components/common/Map"
import AdditionalForms from "../components/common/AdditionalForms"
import InputField from "../components/form/InputField"
import FormWrapper from "../components/form/FormWrapper"
import useFormState from "../components/form/hooks/useFormState"
import AsAdvertisedOn from "../components/common/AsAdvertisedOn"
import isBrandWeBuy from "../components/form/validators/isBrandWeBuy"
import isNotEmpty from "../components/form/validators/isNotEmpty"
import cascade from "../components/form/validators/cascade"
import HaveQuestions from "../components/common/HaveQuestions"
import HowItWorks from "../components/common/HowItWorks"

const SilverQuoteForm = () => {
  const CANONICAL_PATH_NAME = "/what-is-my-sterling-silver-worth/"
  if (typeof window !== `undefined` && window && window.location.pathname !== CANONICAL_PATH_NAME) {
    window.history.replaceState(null, null, CANONICAL_PATH_NAME + (window.location?.search || ""))
  }
  const [form, setForm, updateForm] = useFormState({
    name: "",
    phone: "",
    pieces: "",
    weight: "",
    email: "",
    description: "",
    photos: [],
  })
  const updateUploadedFiles = useCallback(
    files =>
      setForm(f => {
        return { ...f, photos: files }
      }),
    [setForm]
  )
  return (
    <Layout className={"mx-auto"} breadcrumbs={false}>
      <Seo
        title="What is my watch worth? Watch Quote Form"
        description="Discover the hidden value of your sterling silver at SellUsYourJewelry.com. Get the best offer for your precious pieces! Click here to learn more!"
        canonical={CANONICAL_PATH_NAME}
      />

      <section
        id="Sterling Silver Instant Quote Form"
        className="bg-gradient-to-tr from-white to-gray-300 pt-20 md:pt-5 lg:pt-0 py-10 md:py-20"
      >
        <div className="text-center text-3xl font-black text-gray-700 md:text-5xl xl:text-6xl w-full inline-block pb-5 xl:pb-0 lg:py-10 uppercase px-5">
          THE SOLUTION <span className="font-normal">to selling your jewelry</span>
        </div>
        <div className="flex flex-col items-center justify-center lg:flex-row">
          <div className="flex w-full lg:w-10/12 flex-col justify-center xl:p-10 items-center xl:px-20 ">
            <FormWrapper
              className={
                "bg-white p-5 2xl:w-10/12 pl-5 2xl:pl-[25%] relative rounded-xl shadow-xl w-10/12"
              }
              name={"What is my sterling silver worth"}
              action={"/thank-you/"}
              form={form}
              category={"watch"}
            >
              <div className="flex items-center flex-col md:flex-row 2xl:min-w-[500px] w-full 2xl:w-1/3 justify-center 2xl:absolute -bottom-[6%] 2xl:right-[calc(60%+5vw)] z-10 px-10">
                <StaticImage
                  src={"../images/sell-my-sterling-silver-form-header.png"}
                  alt={"Sterling Silver Set"}
                  loading={"lazy"}
                  className="w-full md:w-1/2 2xl:w-full"
                />
              </div>
              <h1 className="text-center w-full text-3xl uppercase hidden 2xl:absolute top-10 left-5 2xl:max-w-[28%] 2xl:block ">
                WHAT IS MY Silver Set WORTH?
              </h1>
              <p className="text-center 2xl:absolute top-40 2xl:px-5 left-5 2xl:max-w-[28%] 2xl:block">
                Fill out the simple form below to receive a free precious metal quote!
              </p>

              <div className="flex flex-col space-y-2">
                <InputField
                  name={"name"}
                  label={"Name"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"phone"}
                  label={"Phone"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"email"}
                  label={"E-Mail"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"pieces"}
                  label={"Total Pieces"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"weight"}
                  label={"Weight in grams"}
                  form={form}
                  onChange={updateForm}
                  required={true}
                />
                <InputField
                  name={"description"}
                  label={"Description"}
                  type={"textarea"}
                  rows={"5"}
                  form={form}
                  onChange={updateForm}
                />
                <br />
                <FileUpload
                  id={"photos"}
                  label={"Add photos"}
                  name={"photos"}
                  accept={".jpg,.png,.jpeg"}
                  updateFilesCb={updateUploadedFiles}
                  multiple
                />
                * Max. file size: 40 MB, Max. files: 5.
                <br />
                <br />
                Please attach photos of items & appraisals/certificates. The red 'X's are normal.
              </div>
              <div className="w-full flex items-center justify-center 2xl:justify-start">
                <button className="bg-[#816230] hover:bg-accentMd duration-200 text-white px-7 py-3 rounded-full my-5">
                  SUBMIT FORM
                </button>
              </div>
            </FormWrapper>
          </div>
        </div>
      </section>

      <HowItWorks className={"w-10/12 mx-auto max-w-[1366px] "} />

      <AdditionalForms />

      <div className="max-2-max flex flex-col justify-center w-10/12 mx-auto max-w-[1366px] ">
        <h3 className="p-10 text-center text-3xl uppercase leading-loose">
          Come Get a Sterling Silver Quote from Us!
        </h3>
        <p className="w-full p-10 text-lg">
          We don’t just pay the value of silver when giving a sterling silver quote. We also
          consider other sterling silver brands and pieces of authentically unique flatware. Contact
          us and speak with one of the top sterling silver bowl buyers in the nation. As the most
          trusted buyer, we will give you the most accurate value for your sterling silver bowls,
          tea sets, or scrap silver.
          <br />
          <br />
          Sell Us Your Jewelry understands that some of your silver bowls have a sentimental value
          worth more than the material which it’s made of, which is why our team of silver
          specialist are experts who have decades of experience in the art of antiquity and vintage
          luxury. We will not only give you the highest sterling silver quote but we will also
          provide you with any details our experts find on your sterling silver.
          <br />
          <br />
          <b>1. Get a Free preliminary Sterling Silver Quote: </b>This is the way you can assure the
          best price by the best sterling silver quote buyer in South Florida. Once you have an idea
          of what the cost of silver is and maybe some information on the silver flatware you want
          to sell silver bowl miami beach you can request a quote for your piece. With us, you don’t
          need more than a few images of your silver flatware for us to consider buying it.
          <br />
          <b>2. Visit the Showroom or talk to their Sterling Experts on the phone: </b>The best way
          to sell silver bowl miami beach is one that gives you confidence in the process. Sell Us
          Your Jewelry gives you the option to walk in the store or talk over the phone with our
          sterling silver buyers. Our sterling silver experts have years of buying experience
          providing them with resources to give you the highest accurate appraisal for your sliver
          flatware when you decide to sell silver bowl miami beach.
          <br />
          <b>3.Request an Easy Shipping Box and Confirm the price for your silver:</b> While some
          silver matching services make you ship them your sterling silver flatware just for a
          quote, we allow you to review our preliminary offer before you ship. We also make it easy
          and simple to send us your sterling silver valuables. Once you review the preliminary
          silver flatware quote, we send you a box where you will be able to carefully wrap and ship
          your valuables directly to us for a final review of your product.
          <br />
          <b>4. Get cash for your metals and sterling silver quote:</b> The entirety of the sterling
          silver flatware process is not one for you to rush through. From the moment you decide to
          sell silver bowl miami beach to the second you get a quote for your piece there are a
          thousand questions that can cross your mind. With Sell Us Your Jewelry, our sterling
          silver quote buyers will help you through every question via phone, email, or in our Miami
          Beach showroom. We make it easy to get the most cash for your silver flatware and we
          strive to provide you with the best service throughout your silver flatware selling
          experience.
          <br />
          <br />
          If you are in South Florida looking for a silver bowl quote, you can come to visit our
          showroom on Miami Beach with your silver and get an appraisal on the spot. Are you in
          another state? We can help you out anywhere in the nation. With our simple to fill Easy
          Ship Box form, you can get a sterling silver quote for your tea or coffee set in less than
          24 hours. We will also let you know about any unique details regarding your sterling
          silver bowl including the original production date. Sterling silver quote are becoming a
          rarity which inflates their value immensely.
        </p>

        <br />
        <Link to="/ez-ship-box-request-form/" className="hoverScale flex w-full justify-center ">
          <StaticImage
            src={"../images/Shipping-Box-request.jpg"}
            alt={"Get an Appraisal for Jewelry - Shipping"}
            loading={"eager"}
          />
        </Link>
      </div>

      <section className="py-20">
        <AsAdvertisedOn />
      </section>

      <div className="w-full py-10 shadow-lg" />

      <HaveQuestions />

      <section className={"mt-16"}>
        <Map />
      </section>
    </Layout>
  )
}

export default SilverQuoteForm
